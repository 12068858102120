module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-823e3e198c/3/buildhome/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.12.3-f2709a1ddc-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Anastasia Zhikhartseva","short_name":"Anastasia Zhikhartseva","start_url":"/","background_color":"#fff","theme_color":"#ccff00","display":"standalone","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"df40aae7a060b88ef80b9c95aea34eb3"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-89eaf3c51a/3/buildhome/.yarn/berry/cache/gatsby-remark-images-npm-7.13.0-6ddddff9ee-10c0.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":650,"withWebp":{"quality":80},"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-f77937bfce/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
