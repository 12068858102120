exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-exhibitions-2024-reality-check-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/exhibitions/2024-reality-check.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-exhibitions-2024-reality-check-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2019-perf-drawing-hero-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2019-perf-drawing-hero.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2019-perf-drawing-hero-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2019-separation-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2019-separation.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2019-separation-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2021-stop-spiraling-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2021-stop-spiraling.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2021-stop-spiraling-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-all-fine-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-all-fine.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-all-fine-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-appetite-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-appetite.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-appetite-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-doves-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-doves.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-doves-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-dress-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-dress.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-dress-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-jule-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-jule.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-jule-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-omon-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-omon.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-omon-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-reddening-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-reddening.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-reddening-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-superprison-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-superprison.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2022-superprison-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2023-keeping-the-eyes-open-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2023-keeping-the-eyes-open.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2023-keeping-the-eyes-open-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2023-melancholia-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2023-melancholia.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-2023-melancholia-mdx" */),
  "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-me-mdx": () => import("./../../../src/pages/exhibitions/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/me.mdx" /* webpackChunkName: "component---src-pages-exhibitions-mdx-frontmatter-slug-js-content-file-path-src-works-me-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-exhibitions-2024-reality-check-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/exhibitions/2024-reality-check.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-exhibitions-2024-reality-check-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2019-perf-drawing-hero-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2019-perf-drawing-hero.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2019-perf-drawing-hero-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2019-separation-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2019-separation.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2019-separation-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2021-stop-spiraling-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2021-stop-spiraling.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2021-stop-spiraling-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-all-fine-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-all-fine.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-all-fine-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-appetite-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-appetite.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-appetite-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-doves-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-doves.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-doves-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-dress-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-dress.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-dress-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-jule-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-jule.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-jule-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-omon-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-omon.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-omon-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-reddening-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-reddening.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-reddening-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-superprison-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2022-superprison.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2022-superprison-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2023-keeping-the-eyes-open-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2023-keeping-the-eyes-open.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2023-keeping-the-eyes-open-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2023-melancholia-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/2023-melancholia.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-2023-melancholia-mdx" */),
  "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-me-mdx": () => import("./../../../src/pages/works/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/works/me.mdx" /* webpackChunkName: "component---src-pages-works-mdx-frontmatter-slug-js-content-file-path-src-works-me-mdx" */)
}

